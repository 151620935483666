import React from 'react'
import Layout from "../../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from 'react-helmet';

function Design() {

  const sitename = "Bart Sallé, freelance webdeveloper";
  const pagetitle = "Design & Content creation - Bart Sallé, freelance webdeveloper";
  const description = "Ik ontwikkel logo's, folders of een compleet nieuwe huisstijl. Daarnaast doe ik ook fotografie!";
  const url = "https://www.bartsalle.nl/diensten/design-en-content-creation/";

  return (
    <Layout>
      <Helmet>
        <title>{pagetitle}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        <meta property="og:locale" content="nl_NL" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={pagetitle} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={url} />
        <meta property="og:site_name" content={sitename} />
        <body className="overzichtdiensten" />
      </Helmet>
        <div className="container overflow">
          <h1>Design & Content creation</h1>
          <p>Ik ben ooit als designer begonnen en ben later met name gaan ontwikkelen. Ik zet echter mijn design skills geregeld nog in bij diverse projecten. Je kunt denken aan het ontwikkelen van logo's, folders of een compleet nieuwe huisstijl.</p>
          <h2>Logo's</h2>
          <p>Een aantal voorbeelden van logo’s die ik ontwikkeld heb, vaak onderdeel van een complete huisstijl vormgeving.</p>
          <div className='imagediv'>
          <StaticImage src="../../../images/portfolio/logo-home67.jpg" alt="voorbeeld logo" layout="fixed" height={50} className="float-left" placeholder="none" />
          <StaticImage src="../../../images/portfolio/logo-human-talent-at-work.jpg" alt="voorbeeld logo" layout="fixed" height={50} className="float-left" placeholder="none" />
          <StaticImage src="../../../images/portfolio/logo-raw.jpg" alt="voorbeeld logo" layout="fixed" height={50} className="float-left" placeholder="none" />
          <StaticImage src="../../../images/portfolio/logo-sd-model-cartuning.jpg" alt="voorbeeld logo" layout="fixed" height={50} className="float-left" placeholder="none" />
          <StaticImage src="../../../images/portfolio/logo-speech-company.jpg" alt="voorbeeld logo" layout="fixed" height={50} className="float-left" placeholder="none" />
          <StaticImage src="../../../images/portfolio/logo-tangle-store.jpg" alt="voorbeeld logo" layout="fixed" height={50} className="float-left" placeholder="none" />
          <StaticImage src="../../../images/portfolio/logo-young-famous.jpg" alt="voorbeeld logo" layout="fixed" height={50} className="float-left" placeholder="none" />
          </div>
          <div className='imagediv'>
          <h2>Brochures, folders, posters etc.</h2>
          <p>Een aantal voorbeelden van brochures en een poster die ik in opdracht ontwikkeld heb.</p>
          <StaticImage src="../../../images/portfolio/folder-young-famous.png" alt="voorbeeld folder" layout="constrained" height={300} className="float-left" placeholder="none" />
          <StaticImage src="../../../images/portfolio/folder-eindbestemming.png" alt="voorbeeld folder" layout="constrained" height={300} className="float-left" placeholder="none" />
          <StaticImage src="../../../images/portfolio/levenslang-poster.jpg" alt="voorbeeld poster" layout="constrained" height={300} className="float-left border" placeholder="none" />
          </div>
        </div>
    </Layout>
  )
}

export default Design